import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";

const NotFoundPage = () => (
  <Layout location={{ pathname: "/404" }}>
    <SEO title="404: Not found" />
    <div style={{ textAlign: "center" }}>
      <h1>NOT FOUND</h1>
      <p>You just hit a route that doesn&#39;t exist.</p>
    </div>
  </Layout>
);

export default NotFoundPage;
